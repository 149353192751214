<script>
import { layoutComputed } from '@/state/helpers';

/**
 * Horizontal-topbar component
 */
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
  },
  components: {},
  data() {
    return {
      navbarUser: {
        displayName: localStorage.getItem('session_name'),
        pathPhoto: localStorage.getItem('session_path_photo'),
        pathPhotoFull:
          process.env.VUE_APP_BACKEND_URL +
          localStorage.getItem('session_path_photo'),
      },
      sessionData: JSON.parse(localStorage.getItem('session')),
      configuration: JSON.parse(localStorage.getItem('configuration')),
    };
  },
  methods: {
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById('topnav-menu-content');
      element.classList.toggle('show');
    },
    initFullScreen() {
      document.body.classList.toggle('fullscreen-enable');
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      // i18n.locale = locale;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'dark':
              document.body.setAttribute('data-topbar', 'dark');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'light':
              document.body.setAttribute('data-topbar', 'light');
              document.body.removeAttribute('data-layout-size', 'boxed');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'colored':
              document.body.setAttribute('data-topbar', 'colored');
              document.body.removeAttribute('data-layout-size', 'boxed');
              break;
            default:
              document.body.setAttribute('data-topbar', 'dark');
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case 'boxed':
              document.body.setAttribute('data-layout-size', 'boxed');
              document.body.removeAttribute('data-layout-scrollable');
              break;
            case 'fluid':
              document.body.setAttribute('data-layout-mode', 'fluid');
              document.body.removeAttribute('data-layout-scrollable');
              document.body.removeAttribute('data-layout-size');
              break;
            case 'scrollable':
              document.body.setAttribute('data-layout-scrollable', 'true');
              document.body.removeAttribute('data-layout-mode');
              document.body.removeAttribute('data-layout-size');
              break;
            default:
              document.body.setAttribute('data-layout-mode', 'fluid');
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="configuration.LOGO_SMALL" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="configuration.LOGO_FULL_DARK" height="22" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="configuration.LOGO_SMALL" height="22" />
            </span>
            <span class="logo-lg">
              <img :src="configuration.LOGO_FULL_LIGHT" height="30" />
            </span>
          </router-link>
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm me-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Search..." />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ms-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <img
              v-if="navbarUser.pathPhoto != null"
              class="rounded-circle header-profile-user"
              v-bind:src="navbarUser.pathPhotoFull"
              alt="Header Avatar"
            />
            <img
              v-else
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-9.png"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="navbarUser">{{ navbarUser.displayName }}&nbsp;</div>
              <div v-else>Smartsoft Studio</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link to="/profile" v-slot="{ navigate }" custom>
              <span @click="navigate" @keypress.enter="navigate">
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                {{ $t('navbar.dropdown.henry.list.profile') }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link to="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
