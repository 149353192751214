export const permissionMenuRole = {
  "Jabatan Kompetensi": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Kategori Studi Kasus": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 0,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  Kompetensi: {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Komptensi - Simulasi Tes": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Komptensi - Setup Soal + All Menu": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 0,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Komptensi - Disable": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 0,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Konfigurasi Timer": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Treshold Nilai": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Jadwal Pelaksanaan": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 1,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Tambah Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Tambah Batch": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Setup Batch": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Peserta": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Export Peserta": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Edit Batch": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Setup Jadwal - Delete Batch": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Edit Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Delete Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 0,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 0,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Monitor Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Report Jadwal": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Report Nilai Kompetensi": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Report Nilai Jabatan": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Report Hasil": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "File Manager": {
    PMK: {
      isWrite: 1,
      isRead: 1,
    },
    SPK: {
      isWrite: 0,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
  "Informasi Ujian": {
    PMK: {
      isWrite: 0,
      isRead: 1,
    },
    SPK: {
      isWrite: 1,
      isRead: 1,
    },
    Manager: {
      isWrite: 0,
      isRead: 1,
    },
    Admin: {
      isWrite: 1,
      isRead: 1,
    },
  },
};
