import { createWebHistory, createRouter } from "vue-router";

import routes from "./routes";
import PermissionService from "../helpers/permissionService";
// import Swal from "sweetalert2";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "hash",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  const authPesertaRequired = routeTo.matched.some(
    (route) => route.meta.authPesertaRequired
  );
  let menuLabel = routeTo?.matched[0].meta.label;
  const isCheck = routeTo.matched.some((route) => route.meta.isCheck);
  const filterNameRoute = localStorage.filtered_name_menu?.split(",") || [];

  if (authRequired) {
    const aksesToken =
      localStorage.access_token || localStorage.access_token_penilai;
    if (aksesToken == null) {
      return next("/login");
    }

    if (routeTo.name != "logout") {
      // const checkToken = await PermissionService.cekToken();
      // if (!checkToken) {
      //   let timerInterval;
      //   Swal.fire({
      //     icon: "warning",
      //     title: "Token anda kadaluarsa",
      //     text: "Silahkan login kembali!",
      //     timer: 2000,
      //     timerProgressBar: true,
      //     showCancelButton: false,
      //     showConfirmButton: false,
      //     willClose: () => {
      //       clearInterval(timerInterval);
      //     },
      //   }).then((result) => {
      //     if (result.dismiss === Swal.DismissReason.timer) {
      //       return next("/logout");
      //     }
      //   });
      // }
    }
    if (isCheck) {
      const data = await PermissionService.getHakAkses(menuLabel);
      // const isWrite = PermissionService.isWrite(menuLabel);
      // const isRead = PermissionService.isRead(menuLabel);

      if (data && !data.is_write && !data.is_read) {
        return next("/403");
      }
      if (data && !data.is_read) {
        return next("/403");
      }

      // if (!isWrite && !isRead) {
      //   return next("/403");
      // }

      localStorage.setItem("isWrite", data.is_write);
      localStorage.setItem("isRead", data.is_read);
      // localStorage.setItem('isWrite', isWrite)
      // localStorage.setItem('isRead', isRead)
    }
    if (isCheck && !filterNameRoute.includes(menuLabel)) {
      return next("/403");
    }

    next();
  } else {
    next();
  }
  if (authPesertaRequired) {
    const aksesToken = localStorage.access_token;
    if (aksesToken == null) {
      return next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
