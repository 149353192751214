<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
.container-image-soal {
  position: relative;
  width: 100%;
}

.container-image {
  position: relative;
  width: 100px;
}

.image {
  opacity: 1;
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.image-soal {
  opacity: 1;
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  cursor: pointer;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  text-align: center;
}

.container-image-soal:hover .middle {
  opacity: 1;
}

.container-image:hover .middle {
  opacity: 1;
}
</style>
