<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Edit Menu",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Menu",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Menu",
          href: "/master/menu",
        },
        {
          text: "Edit Menu",
          active: true,
        },
      ],

      // Catch Error Axios
      axiosCatchError: null,

      // variabel referensi
      optionsMenuParent: [],

      // variable Page
      id: this.$route.params.id,
      menu_tipe: "",

      form_menu: false,
      nama_menu: null,
      icon_menu: null,
      link_path_menu: null,
      link_name_menu: null,
      urutan_menu: null,
      parent_menu: [],

      form_label: false,
      nama_label: null,
      urutan_label: null,
    };
  },
  mounted() {
    let self = this;
    Swal.fire({
      title: '<i class="fas fa-spinner fa-spin"></i>',
      text: "Loading...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    // load data menu parent
    var config_data_menu_parent = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/menu-parent",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_menu_parent)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsMenuParent = response.data.data.referensi;
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // get data menu berdasarkan id
    var config = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu",
      params: {
        id: self.id,
      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data[0];
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix[0];
          self.menu_tipe = data_edit.menu_master_type;
          if (self.menu_tipe == "MENU") {
            self.form_menu = true;
            self.nama_menu = data_edit.menu_master_nama;
            self.icon_menu = data_edit.menu_master_icon;
            self.link_path_menu = data_edit.menu_master_link_path;
            self.link_name_menu = data_edit.menu_master_link_name;
            self.urutan_menu = data_edit.menu_master_urutan;
          } else {
            self.form_label = true;
            self.nama_label = data_edit.menu_master_nama;
            self.urutan_label = data_edit.menu_master_urutan;
          }

          if (data_edit.menu_master_parent != 0) {
            self.getMenuParent(data_edit.menu_master_parent);
          } else {
            Swal.close();
          }
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  },
  methods: {
    getMenuParent(id_menu) {
      let self = this;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu",
        params: {
          id: id_menu,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            var data_edit = response_data_fix[0];
            self.parent_menu = data_edit;
            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.close();
        });
    },
    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "put",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/menu",
        data: {
          id: self.id,
          menu_tipe: self.menu_tipe,
          form_menu: self.form_menu,
          nama_menu: self.nama_menu,
          icon_menu: self.icon_menu,
          link_path_menu: self.link_path_menu,
          link_name_menu: self.link_name_menu,
          urutan_menu: self.urutan_menu,
          parent_menu: self.parent_menu?.id_menu_master,
          form_label: self.form_label,
          nama_label: self.nama_label,
          urutan_label: self.urutan_label,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master menu segera",
            timer: 2000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({ name: "all-menu" });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div
                  class="alert alert-danger"
                  v-if="
                    typeof axiosCatchError === 'string' ||
                    axiosCatchError instanceof String
                  "
                >
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Tipe Menu</label>
                    <select
                      class="form-select"
                      id="tipe_menu"
                      v-on:change="tipe_form"
                      v-model="menu_tipe"
                      disabled="true"
                    >
                      <option value="" selected>Pilih Tipe Menu...</option>
                      <option value="LABEL">LABEL</option>
                      <option value="MENU">MENU</option>
                    </select>
                  </div>

                  <div v-show="form_menu" style="margin-top: 15px">
                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Nama Menu"
                        label-for="nama_menu"
                      >
                        <b-form-input
                          id="nama_menu"
                          placeholder="Masukkan Nama Menu..."
                          type="text"
                          v-model="nama_menu"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Icon Menu"
                        label-for="icon_menu"
                      >
                        <b-form-input
                          id="icon_menu"
                          placeholder="Masukkan Icon Menu..."
                          type="text"
                          v-model="icon_menu"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Link Path Menu"
                        label-for="link_path_menu"
                      >
                        <b-form-input
                          id="link_path_menu"
                          placeholder="Masukkan Link Path Menu..."
                          type="text"
                          v-model="link_path_menu"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Link Name Menu"
                        label-for="link_name_menu"
                      >
                        <b-form-input
                          id="link_name_menu"
                          placeholder="Masukkan Link Name Menu..."
                          type="text"
                          v-model="link_name_menu"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Urutan Menu"
                        label-for="urutan_menu"
                      >
                        <b-form-input
                          id="urutan_menu"
                          placeholder="Masukkan Urutan Menu..."
                          type="number"
                          v-model="urutan_menu"
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-md-12">
                      <b-form-group
                        class="mb-3"
                        label="Parent Menu"
                        label-for="parent_menu"
                      >
                        <v-select
                          id="parent_menu"
                          :options="optionsMenuParent"
                          label="menu_master_nama"
                          v-model="parent_menu"
                          placeholder="Pilih Parent Menu"
                        ></v-select>
                      </b-form-group>
                    </div>
                  </div>

                  <div v-show="form_label" style="margin-top: 15px">
                    <div class="col-lg-12">
                      <b-form-group
                        class="mb-3"
                        label="Nama Label"
                        label-for="nama_label"
                      >
                        <b-form-input
                          id="nama_label"
                          placeholder="Masukkan Nama Label..."
                          type="text"
                          v-model="nama_label"
                        ></b-form-input>
                      </b-form-group>

                      <div class="col-lg-12">
                        <b-form-group
                          class="mb-3"
                          label="Urutan Label"
                          label-for="urutan_label"
                        >
                          <b-form-input
                            id="urutan_label"
                            placeholder="Masukkan Urutan Label..."
                            type="number"
                            v-model="urutan_label"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start">
                    <router-link
                      :to="{ name: 'all-menu' }"
                      class="btn btn-warning"
                      ><i class="fa fa-arrow-left"></i> Kembali</router-link
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    <b-button type="reset" variant="danger"
                      ><i class="fa fa-redo-alt"></i> Reset</b-button
                    >
                    &nbsp;
                    <b-button type="submit" variant="primary"
                      ><i class="fa fa-save"></i> Simpan</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
