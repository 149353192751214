import axios from "axios";
import { permissionMenuRole } from "./permissionMenuRole";

export default {
  cekToken,
  getHakAkses,
  setGroupRole,
  isWrite,
  isRead,
};

async function cekToken() {
  return await axios({
    method: "get",
    url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-token",
    headers: {
      Authorization: `Bearer ${localStorage.access_token}`,
    },
  })
    .then((response) => response)
    .catch((err) => {
      console.log("err check token:", err);
      return false;
    });
}

export async function getHakAkses(menu) {
  const data = await axios({
    method: "get",
    url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/get-hak-akses",
    params: {
      menu: menu,
      role: localStorage.getItem("role_cermat"),
    },
    headers: {
      Authorization: `Bearer ${localStorage.access_token}`,
      Accept: "Application/json",
    },
  })
    .then((response) => response.data.data)
    .catch((err) => {
      console.log("err get hak akses", err);
      return false;
    });
  if (data) {
    return data;
  }
  return { is_read: false, is_write: false };
}

export function setGroupRole(role) {
  const pmk = [
    "SPV 1 SIS",
    "Staff Evaluasi Sertifikas",
    "SPV 1 PPS",
    "Staff Perencanaan",
    "SPV 1 PSP",
    "MAN 1 PMK",
    "Staff Kinerja",
  ];
  const spk = ["MAN 1 SPU", "Staff Pelaksanaan", "Staff Keuangan", "SPV 1 KEU"];
  const admin = ["Admin"];
  // const manager = ["MUPS"];

  if (admin.includes(role)) localStorage.setItem("role_group_cermat", "Admin");
  else if (pmk.includes(role))
    localStorage.setItem("role_group_cermat", "Admin");
  else if (spk.includes(role))
    localStorage.setItem("role_group_cermat", "Admin");
  else localStorage.setItem("role_group_cermat", "Admin");
}

export function isWrite(param) {
  const groupRole = localStorage.getItem("role_group_cermat");
  if (permissionMenuRole[param] === undefined) {
    return 0;
  }
  return permissionMenuRole[param][groupRole].isWrite;
}

export function isRead(param) {
  const groupRole = localStorage.getItem("role_group_cermat");
  if (permissionMenuRole[param] === undefined) {
    return 0;
  }
  return (
    permissionMenuRole[param][groupRole].isWrite ||
    permissionMenuRole[param][groupRole].isRead
  );
}
